export const MenuItems = [
    {
        title: 'Home',
        url: 'home',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: 'about',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        url: 'projects',
        cName: 'nav-links'
    },
    {
        title: 'Photos',
        url: 'photos',
        cName: 'nav-links'
    }
]